import React, { useCallback, useRef } from "react"
import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"

var FontFaceObserver = require("fontfaceobserver")

gsap.registerPlugin(SplitText)

const SplitHeading = props => {
  const { children } = props,
    { h1 } = props,
    { classString } = props

  const heading = useRef(),
    splitHeading = useRef()

  /* eslint-disable */
  const cleanText = useCallback(e => {
    if (typeof e === "string") {
      return cleanText(document.querySelectorAll(e))
    } else if (e[0] && e[0].innerHTML) {
      for (var i = 0; i < e.length; i++) {
        cleanText(e[i])
      }
      return
    }
    e.innerHTML = e.innerHTML
      .replace(/\-/g, "‑")
      .replace(/V/g, "‌V‌")
      .replace(/\./g, "‌.‌")
      .replace(/,/g, "‌,‌")
      .replace(/A/g, "‌A‌")
      .replace(/fi/g, "f‌i")
  }, [])

  const resetSplit = useCallback(() => {
    splitHeading.current.revert()
    splitHeading.current = new SplitText(heading.current, { type: "lines" })
    splitHeading.current.words.forEach(item => {
      item.setAttribute("aria-hidden", true)
    })
  }, [])

  var font;
  if(typeof window != "undefined"){
    font = new FontFaceObserver("Wallop")
  }

  const headingRef = useCallback(node => {
    if (node !== null) {
      font.load().then(
        function () {
          heading.current = node
          cleanText(heading.current)
          splitHeading.current = new SplitText(node, { type: "lines" })
          splitHeading.current.lines.forEach(item => {
            item.setAttribute("aria-hidden", true)
          })
          setTimeout(() => {
            if (heading.current) {
              heading.current.classList.add("split")
            }
          }, 100)
          if(typeof window != "undefined"){
            window.addEventListener("resize", resetSplit)

          }
        },
        function () {
          console.log("Font is not available")
        }
      )
    }
  }, [])

  /* eslint-enable */

  if (h1) {
    return (
      <h1
        className={classString ? classString : null}
        ref={headingRef}
        aria-label={children}
      >
        {children}
      </h1>
    )
  } else {
    return (
      <h2
        className={classString ? classString : null}
        ref={headingRef}
        aria-label={children}
      >
        {children}
      </h2>
    )
  }
}

export default SplitHeading
