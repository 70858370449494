// Core
import React, { useRef } from "react"
import Img from "gatsby-image"

// InView detector
import { InView } from "react-intersection-observer"

// Components
import JsonText from "../_jsonText"
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitHeading from "../split-heading/split-heading"
import SplitText from "../split-text/split-text"

// Styles
import styles from "./profile.module.scss"
import heroStyles from "../hero/hero.module.scss"

const Profile = props => {
  const { name, title, bio, image } = props

  const containerRef = useRef()

  return (
    <ScrollSection>
      <section className={styles.profile}>
        <div className="container">
          <InView triggerOnce={true}>
            {({ inView, ref, entry }) => (
              <div
                className="cols flex flex--center"
                ref={ref}
                inview={inView.toString()}
              >
                <div
                  className={`col m10 t4 ${styles.image}`}
                  data-scroll
                  ref={containerRef}
                  id={`profile-container`}
                >
                  <div
                    className={`
                    ${styles.heroImage}`}
                    data-scroll
                    data-scroll-sticky
                    data-scroll-target={`#profile-container`}
                  >
                    <div
                      className={`${heroStyles.heroImageTransform} ${styles.heroImageHeight}`}
                    >
                      <div className={heroStyles.heroImageInner}>
                        <div className={heroStyles.imageWrap}>
                          <Img
                            fluid={image.fluid}
                            alt={image.description}
                            objectFit="cover"
                            objectPosition="top"
                            imgStyle={{ objectPosition: "top" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col m11 t5 dl4 ${styles.content}`} data-scroll>
                  <SplitHeading h1={true} classString={`h2 ${styles.heading}`}>
                    {name}
                  </SplitHeading>
                  <SplitText classString={`copy copy--s ${styles.title}`}>
                    {title}
                  </SplitText>

                  <JsonText content={bio} split small />
                </div>
              </div>
            )}
          </InView>
        </div>
      </section>
    </ScrollSection>
  )
}

export default Profile
